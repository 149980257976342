<template>
  <!-- 兴趣 -->
  <div class="interest">
    <!--    我的兴趣开始-->
    <div class="interest-me">
      <div class="interest-title">我的兴趣</div>
      <div class="interest-con flex-nowrap">
        <div class="name-of-interest" v-for="item in userInterests" :key="item.id" @click="cancelInterest(item.tag_id)">{{ item.tag.name }}</div>
      </div>
    </div>
    <!--    我的兴趣结束-->
    <!--    全部兴趣开始-->
    <div class="interest-all">
      <div class="interest-title">全部兴趣</div>
      <div class="interest-con">
        <div class="name-of-interest" :class="[((interestsArr.indexOf(item.id) > -1) || item.isuserInterests) ? '' : 'not-selected']" v-for="(item,index) in interest" :key="item.id"
             @click="choiceInterests(item.id)">
          {{ item.name }}
        </div>
      </div>
    </div>
    <!--    全部兴趣结束-->

    <div class="bot-height safety-height"></div>

    <div class="determine-warp safety-height">
      <div class="determine" :class="userInterests.length === interest.length ? 'placement' : ''" @click="submitInterests" >确定</div>
    </div>
    <van-toast id="van-toast" />
  </div>
</template>

<script>

import { reactive } from "vue"
export default {
  name: "address-index",
  data(){
    return{
      interest: [],    //全部兴趣
      userInterests:[],//用户兴趣
      interestsArr:[],
        isSubmit: false
    }
  },
  created() {
    this.getInterests()
    this.getTag()
  },
  methods:{
    //获取用户兴趣
    getInterests(){
      let _this = this
      let url = _this.$api.Interests
      _this.$http.get(url,true).then(res => {
        if (res.data.success){
          _this.userInterests = res.data.data
        }
      })
    },
    //获取全部兴趣
    getTag(){
      let _this = this
      let url = _this.$api.Tags
      _this.$http.get(url,true).then(res => {
        if (res.data.success){
          _this.interest = res.data.data.interest
          _this.interest.forEach(item => {
            item.id
          })
          setTimeout(()=>{
            for (let i = 0; i < _this.interest.length; i ++){
              var bl = true
              for (let j = 0; j < _this.userInterests.length; j ++){
                if( _this.interest[i].id === _this.userInterests[j].tag_id){
                  bl = false
                  break
                }
              }
              if (bl) {
                _this.interest[i].isuserInterests = false
              }else {
                _this.interest[i].isuserInterests = true
              }
              reactive(this.interest)
            }
          },100)

        }
      })
    },

    // 选择兴趣
    choiceInterests(id){
      let arrIndex = this.interestsArr.indexOf(id);
      if(arrIndex>-1){
        this.interestsArr.splice(arrIndex,1);
      }else{
        this.interestsArr.push(id);
      }
    },
    // 提交兴趣
    submitInterests(){
      let _this = this
      let url = _this.$api.Interests
      let interest = []

      interest = _this.userInterests.map(item =>{
        item = item.tag_id
        return item
      })
        if (_this.interestsArr.length === 0){
            return
        }
        _this.interestsArr.push(interest.toString())
        if(_this.isSubmit) return
        _this.isSubmit = true
      let data = {
        "ids":_this.interestsArr.toString()
      }
      if (_this.interestsArr.length === 0){
        this.$toast('请选择兴趣');
        return
      }
      _this.$http.post(url,data,true).then(res => {
        if (res.data.success){
            _this.isSubmit = false
          _this.interestsArr = []
          _this.getInterests()
          _this.getTag()

        }
      })
    },
    /*点击我的兴趣*/
    cancelInterest(id){
      let _this = this
      let userInterests = []
      _this.interest = _this.interest.map(item =>{
        return item
      })
        if(_this.userInterests.length == 1) return
      userInterests = _this.userInterests.filter(item =>{
        item = item.tag_id
        return item !== id
      })
      let url = _this.$api.Interests
      userInterests = userInterests.map(item =>{
        item = item.tag_id
        return item
      })
      let data = {
        "ids": userInterests.toString()
      }
      _this.$http.post(url,data,true).then(res => {
        if (res.data.success){
          _this.interestsArr = []
          _this.getInterests()
          _this.getTag()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~assets/scss/user/interest/interest.scss";
</style>